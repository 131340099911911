<template>
  <div>
    <div class="">
      <!-- Card -->
      <p class="alert alert-primary mb-3">
        <i class="far fa-info me-2"></i>From here you can manage your library of
        tests.
      </p>

      <div class="row mb-3">
        <div class="col">
          <form @submit.prevent="addNewTest">
            <div class="row mb-3">
              <div class="col">
                <label for>Code</label>
                <input
                  type="text"
                  v-model="form.code"
                  class="form-control"
                  placeholder="1234"
                  required
                />
              </div>

              <div class="col">
                <label for>Type</label>
                <input
                  type="text"
                  v-model="form.type"
                  class="form-control"
                  placeholder="ex. Laboratory"
                  required
                />
              </div>

              <div class="col">
                <label for>Price</label>
                <input
                  type="text"
                  v-model="form.price"
                  class="form-control"
                  placeholder="Price"
                />
              </div>
            </div>
            <!--  -->

            <div class="row mb-3">
              <div class="col">
                <label for>Category</label>
                <select
                  placeholder="Select Category"
                  class="form-control"
                  v-model="form.category_id"
                >
                  <option v-for="c in categories" :key="c.id" :value="c.id">
                    {{ c.name }}
                  </option>
                </select>
              </div>

              <div class="col">
                <label for>Supplier</label>
                <input
                  type="text"
                  v-model="form.supplier"
                  class="form-control"
                  placeholder="Supplier"
                  required
                />
              </div>

              <div class="col">
                <label for>Description</label>
                <input
                  type="text"
                  v-model="form.description"
                  class="form-control"
                  placeholder="Description"
                  required
                />
              </div>
            </div>

            <!--  -->

            <div class="row mb-3">
              <div class="col">
                <label for>Comments</label>
                <textarea
                  v-model="form.comments"
                  class="form-control"
                  placeholder="Comments"
                  rows="3"
                ></textarea>
              </div>
            </div>

            <!--  -->

            <div class="row">
              <div class="col-auto ms-auto">
                <button class="btn btn-primary" type="submit">
                  <i class="far fa-check me-2"></i>Add Test
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-auto my-auto align-items-center form-inline">
          <select
            class="form-control form-control-sm me-2"
            v-model="searchType"
          >
            <option value="description">Description</option>
            <option value="category">Category</option>
            <option value="code">Code</option>
          </select>
          <input
            type="text"
            v-model="search"
            class="form-control form-control-sm me-2"
            autocomplete="offington"
            placeholder="Enter search query"
          />
        </div>
        <div class="col-auto ms-auto form-inline">
          <div class="m-4">
            <button
              class="btn btn-sm btn-outline-dark me-2"
              :class="desc == false ? 'active' : ''"
              @click="desc = false"
            >
              <i class="far fa-sort-size-up"></i>
            </button>
            <button
              class="btn btn-sm btn-outline-dark"
              :class="desc == true ? 'active' : ''"
              @click="desc = true"
            >
              <i class="far fa-sort-size-down"></i>
            </button>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-light btn-sm dropdown-toggle"
              type="button"
              id="sortingDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="far fa-sort me-2"></i>
              <span class="text-capitalize">{{ sorting | formatLabel }}</span>
            </button>
            <div class="dropdown-menu" aria-labelledby="sortingDropdown">
              <button
                class="dropdown-item"
                type="button"
                @click="sortBy('code')"
              >
                Code
              </button>
              <button
                class="dropdown-item"
                type="button"
                @click="sortBy('description')"
              >
                Description
              </button>
              <button
                class="dropdown-item"
                type="button"
                @click="sortBy('price')"
              >
                Price
              </button>
              <button
                class="dropdown-item"
                type="button"
                @click="sortBy('created_at')"
              >
                Created At
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <table class="table table-sm table-hover longTextWrap">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Type</th>
                <th scope="col">Supplier</th>
                <th scope="col">Description</th>
                <th scope="col">Category</th>
                <th scope="col">Price</th>
                <th scope="col">Comments</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="p in searchTests" :key="p.id">
                <th scope="row">
                  <span v-if="editTest !== p">{{ p.code | formatCode }}</span>
                  <span v-else>
                    <input
                      type="text"
                      v-model="p.code"
                      class="form-control"
                      @change="updateTest(false)"
                    />
                  </span>
                </th>
                <td>
                  <span v-if="editTest !== p">{{ p.type }}</span>
                  <span v-else>
                    <select v-model="p.type" class="form-control">
                      <option value="" selected disabled>Select a Type</option>
                      <option value="functional">Functional</option>
                      <option value="laboratory">Laboratory</option>
                      <option value="other">Other</option>
                    </select>
                  </span>
                </td>
                <td>
                  <span v-if="editTest !== p">{{ p.supplier }}</span>
                  <span v-else>
                    <input
                      type="text"
                      v-model="p.supplier"
                      class="form-control"
                      @change="updateTest(false)"
                    />
                  </span>
                </td>
                <td>
                  <span v-if="editTest !== p">{{ p.description }}</span>
                  <span v-else>
                    <input
                      type="text"
                      v-model="p.description"
                      class="form-control"
                      @change="updateTest(false)"
                    />
                  </span>
                </td>
                <td>
                  <span v-if="editTest !== p">{{
                    categoryByID(p.category_id)
                  }}</span>
                  <span v-else>
                    <select
                      placeholder="Select Category"
                      class="form-control"
                      v-model="p.category_id"
                    >
                      <option v-for="c in categories" :key="c.id" :value="c.id">
                        {{ categoryByID(c.id) }}
                      </option>
                    </select>
                  </span>
                </td>
                <td>
                  <span v-if="editTest !== p">£{{ p.price }}</span>
                  <span v-else>
                    <input
                      type="text"
                      v-model="p.price"
                      class="form-control"
                      @change="updateTest(false)"
                    />
                  </span>
                </td>
                <td>
                  <span v-if="editTest !== p">{{ p.comments }}</span>
                  <span v-else>
                    <input
                      type="text"
                      v-model="p.comments"
                      class="form-control"
                      @change="updateTest(false)"
                    />
                  </span>
                </td>
                <td class="text-end">
                  <span v-if="editTest !== p">
                    <button
                      class="btn btn-light btn-xs me-1"
                      @click="editTest = p"
                    >
                      <i class="far fa-pencil"></i>
                    </button>
                    <button class="btn btn-light btn-xs" @click="deleteTest(p)">
                      <i class="far fa-trash"></i>
                    </button>
                  </span>
                  <span v-else>
                    <button
                      class="btn btn-outline-danger btn-xs me-1"
                      @click="editTest = null"
                    >
                      <i class="far fa-times"></i>
                    </button>
                    <button
                      class="btn btn-primary btn-xs"
                      @click="updateTest(true)"
                    >
                      <i class="far fa-save"></i>
                    </button>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["path", "user"],
  data() {
    return {
      tests: [],
      categories: [],
      form: {
        clinic_id: this.user.clinic.id,
        user_id: this.user.id,
        code: "",
        type: "",
        description: "",
        supplier: "",
        comments: "",
        price: 0,
      },
      editTest: null,
      desc: false,
      sorting: null,
      search: "",
      searchType: "description",
    };
  },
  methods: {
    categoryByID(id) {
      var category = "Uncategorised";
      this.categories.filter(function (obj) {
        if (obj.id == id) {
          category = obj.name;
          return true;
        }
      });

      return category;
    },
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tests/categories").then(({ data }) => {
        this.categories = data.data;
      });
    },
    sortBy(sortKey) {
      this.sorting = sortKey;
    },
    fetchTests() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tests").then(({ data }) => {
        this.tests = data;
      });
    },
    addNewTest() {
      this.$axios.post(process.env.VUE_APP_API_URL + "/tests", this.form).then(({ data }) => {
        this.form = {
          clinic_id: this.user.clinic.id,
          user_id: this.user.id,
          sku: "",
          title: "",
          description: "",
          dosage: "",
          comments: "",
          price: "0",
        };
        this.fetchTests();
        this.$EventBus.$emit("alert", data);
      });
    },
    deleteTest(test) {
      if (
        confirm(
          "Are you sure you wish to delete this test, this action cannot be undone!?"
        )
      ) {
        this.$axios.delete(process.env.VUE_APP_API_URL + "/tests/" + test.id).then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchTests();
        });
      }
    },
    updateTest(close) {
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/tests/" + this.editTest.id, this.editTest)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          if (close) {
            this.editTest = null;
          }
        });
    },
  },
  computed: {
    orderedTests: function () {
      if (this.desc == false) {
        return _.orderBy(this.tests, this.sorting);
      } else {
        return _.orderBy(this.tests, this.sorting).slice().reverse();
      }
    },
    searchTests() {
      if (this.search && this.search.length > 0) {
        console.log("searching");
        return this.orderedTests.filter((item) => {
          if (this.searchType == "description") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.description.toLowerCase().includes(v));
          } else if (this.searchType == "code") {
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) => item.code.toLowerCase().includes(v));
          } else if (this.searchType == "category") {
            let category = this.categoryByID(item.category_id).toLowerCase();
            console.log(category);
            return this.search
              .toLowerCase()
              .split(" ")
              .every((v) =>
                category.includes(v)
              );
          }
        });
      } else {
        return this.orderedTests;
      }
    },
  },
  mounted() {
    this.fetchTests();
    this.getCategories();
  },
  filters: {
    formatCode(thing) {
      return thing ? thing : "-";
    },
    formatLabel(str) {
      if (!str) {
        return "Sorting";
      } else {
        return str.replace(/_/g, " ");
      }
    },
  },
};
</script>

<style scoped>
.longTextWrap {
  table-layout: fixed;
  word-wrap: break-word;
}
</style>