<template>
  <div class="row">
    <div class="col-md-12">
      <p class="alert alert-primary mb-3">
        <i class="far fa-info me-2"></i>From here you can manage your
        tests categories and organise them as you need.
      </p>
      <form @submit.prevent="addNewCategory">
        <div class="row mb-3">
          <div class="col">
            <input
              v-model="newCategory"
              :placeholder="'Add a new tests category to your library'"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-end">
            <button class="btn btn-primary" type="submit">
              <i class="far fa-check me-2"></i>
              Save Category
            </button>
          </div>
        </div>
      </form>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="c in categories" :key="c.id">
          <div class="row">
            <div class="col my-auto border-right">
              <div v-if="editCategory !== c" v-html="c.name"></div>
              <div v-else>
                <input v-model="c.name" type="text" class="form-control" />
              </div>
            </div>
            <div class="col-auto my-auto ms-auto">
              <div v-if="editCategory !== c">
                <button
                  class="btn btn-light btn-sm me-2"
                  @click="editCategory = c"
                >
                  <i class="far fa-pencil me-2"></i>Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deleteCategory(c)"
                >
                  <i class="far fa-trash me-2"></i>Delete
                </button>
              </div>
              <div v-else>
                <button
                  class="btn btn-light btn-sm me-2"
                  @click="editCategory = null"
                >
                  <i class="far fa-times me-2"></i>Cancel
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="updateCategory(c)"
                >
                  <i class="far fa-save me-2"></i>Save
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [],
      editCategory: null,
      newCategory: null,
    };
  },
  methods: {
    getCategories() {
      this.$axios.get(process.env.VUE_APP_API_URL + "/tests/categories").then(({ data }) => {
        this.categories = data.data;
      });
    },
    addNewCategory() {
      this.$axios
      .post(process.env.VUE_APP_API_URL + "/tests/categories", {
          name: this.newCategory,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.getCategories();
        });
    },
    deleteCategory(category) {
      if (
        confirm(
          "Are you sure you wish to delete this category, this action cannot be undone!?"
        )
      ) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/tests/categories/" + category.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.getCategories();
          });
      }
    },
    updateCategory(category) {
      this.$axios
        .put(process.env.VUE_APP_API_URL + "/tests/categories/" + category.id, {
          name: category.name,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.getCategories();
        });
    },
  },
  mounted() {
    this.getCategories();
  },
};
</script>