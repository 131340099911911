<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h4 class="fw-bold text-primary">Tests</h4>
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item me-2" @click="handlePage(0)">
            <a
              href="#Tests"
              :class="viewPage == 0 ? 'nav-link active' : 'nav-link'"
              >Tests</a
            >
          </li>
          <li class="nav-item me-2" @click="handlePage(1)">
            <a
              href="#Categories"
              :class="viewPage == 1 ? 'nav-link active' : 'nav-link'"
              >Categories</a
            >
          </li>
        </ul>
         <div v-if="viewPage == 0">
          <tests-index :path="path" :user="user"></tests-index>
        </div>
        <div v-if="viewPage == 1">
          <tests-categories></tests-categories>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TestsCategories from "./tests-partials/categories";
import TestsIndex from "./tests-partials/tests";

export default {
  props: [],
  data() {
    return {
      viewPage: 0,
    };
  },
  methods: {
    handlePage(index) {
      this.viewPage = index;
    },
  },
  components: {

    TestsCategories,
    TestsIndex,
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$stash.path;
    }
  },
};
</script>

<style>
</style>